<template>
  <div class="detailed">
    <!-- 顶部导航 -->
    <van-nav-bar class="topnav" title="积分明细" left-arrow @click-left="toBack" :border="false" />
    <!-- 积分 -->
    <div class="jifen">
      <h3>
        可用积分
        <span>{{ky_score}}</span>
      </h3>
    </div>
    <!-- 积分记录 -->
    <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
      <div class="jf_record">
        <h3>积分记录</h3>
        <van-list
          v-model:loading="loading"
          class="article1"
          :finished="finished"
          :finished-text="scoreLog.length == 0 ? '' : '没有更多了'"
          @load="getList"
        >
          <div class="record_item" @click="itemClick(item)" v-for="(item,index) in scoreLog" :key="index">
            <div class="name">
              <span class="title">{{item.source}}</span>
              <span class="time">{{item.created_at}}</span>
            </div>
            <div class="score" v-if="item.type == 1">+{{item.curr_score}}</div>
            <div class="score" style="color:#000;" v-else>-{{item.curr_score}}<van-icon name="arrow" /></div>
          </div>
        </van-list>
        <van-empty v-if="!loading && scoreLog.length == 0" description="暂无数据" />
      </div>
    </van-pull-refresh>
  </div>
</template>

<script>
import Cookies from "js-cookie";
export default {
  data() {
    return {
      ky_score: null, //可用积分
      scoreLog: [], //明细列表
      pages: {
        page: 1,
        size: 10
      },
      refreshing: false, //下拉刷新
      loading: false,
      finished: false
    };
  },
  created() {
    this.ky_score = Cookies.get("kyscore");
    // this.getList();
    let that = this;
    let ps = {
      token: Cookies.get("spToken")
    };
    that.$api.my.detail(ps).then(function(response) {
      if (response.code === 200) {
        // console.log(response.result)
        that.ky_score = response.result.score;
      }
    });
  },
  methods: {
    // 积分兑换 点击跳转 兑换详情
    itemClick(item) {
      if (item.type == 1) return
      this.toPath('/dui_detail',{id: item.gx_id})
    },
    toPath(url, obj) {
      if (this.$route.fullPath == url) {
        return;
      }
      if (this.$route.path == url) {
        this.$router.push({
          path: url,
          query: obj
        });
        window.location.reload();
        return;
      }
      this.$router.push({
        path: url,
        query: obj
      });
    },
    onRefresh() {
      let that = this;
      that.refreshing = false;
      that.scoreLog = [];
      that.loading = true;
      that.finished = false;
      that.pages.page = 1;
      that.getList();
    },
    toBack() {
      this.$router.go(-1);
    },
    getList() {
      var that = this;
      var params = {
        token: Cookies.get("spToken"),
        page: that.pages.page,
        size: that.pages.size
      };
      that.$api.my.scoreLog(params).then(function(res) {
        if (res.code == 200) {
          that.scoreLog = that.scoreLog.concat(res.result);
          that.pages.page++;
          // 加载状态结束
          that.loading = false;
          if (res.result.length < 1) {
            that.finished = true;
          }
        }
      });
    }
  }
};
</script>

<style>
</style>